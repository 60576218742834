import { RemixBrowser } from '@remix-run/react'
import { startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { EnvEnum } from 'types/enums.ts'

if (ENV.MODE === EnvEnum.PRODUCTION && ENV.SENTRY_DSN) {
  import('~/utils/sentry/monitoring.client.tsx').then(({ init }) => init())
}

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />)
})
